<template>
	<section>
		<screenshot name-pdf="Lojística"></screenshot>
		<b-card>
			<b-card-body>
				<b-row>
					<b-col>
						<ag-grid-table :configUrl="lojisticaMatrizAConfig" :dataUrl="lojisticaMatrizAData"
							:editUrl="lojisticaMatrizAEdit">
						</ag-grid-table>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<ag-grid-table :configUrl="lojisticaMatrizBConfig"
							:dataUrl="lojisticaMatrizBData" :editUrl="lojisticaMatrizBEdit">
						</ag-grid-table>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</section>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BButton,
	BFormFile,
	BCardTitle,
	BCardText,
	BCardBody,
	BTable,
	BTableSimple,
	BTbody,
	BThead,
	BTfoot,
	BTr,
	BTd,
	BTh,
	VBPopover,
	VBTooltip,
} from "bootstrap-vue";

import Screenshot from "@/views/clients/fiordo/components/Screenshot";
import axios from "@axios";
import useApiServices from "@/services/useApiServices.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";

var qs = require('qs');

export default {
	name: "Lojistica",
	components: {
		BRow,
		BCol,
		BCard,
		BButton,
		BFormFile,
		BCardTitle,
		BCardText,
		BCardBody,
		BTable,
		BTableSimple,
		BTbody,
		BThead,
		BTfoot,
		BTr,
		BTd,
		BTh,
		VBPopover,
		VBTooltip,
		Screenshot,
		AgGridTable,
	},
	directives: {
		"b-tooltip": VBTooltip,
		"b-popover": VBPopover,
	},
	props: {
		forecast: {
			type: Object,
		}
	},
	data() {
		return {
			lojisticaMatrizAConfig: useApiServices.lojisticaMatrizAConfig,
			lojisticaMatrizAData: useApiServices.lojisticaMatrizAData,
			lojisticaMatrizAEdit: useApiServices.lojisticaMatrizAEdit,
			lojisticaMatrizBConfig: useApiServices.lojisticaMatrizBConfig,
			lojisticaMatrizBData: useApiServices.lojisticaMatrizBData,
			lojisticaMatrizBEdit: useApiServices.lojisticaMatrizBEdit,
		};
	},
	created() {
	},
	mounted() {
	},
	// methods: {
	// 	centroChange(checked) {
	// 		let api = this.$refs['grid-historico-cages'].gridApi

	// 		if (api) {
	// 			// get filter instance
	// 			const countryFilterComponent = api.getFilterInstance('meta_data.lojistica_configB_calbuco');

	// 			if (countryFilterComponent) {
	// 				// get filter model
	// 				//const model = countryFilterComponent.getModel(); 

	// 				// set filter model and update
	// 				countryFilterComponent.setModel({ values: checked });

	// 				// refresh rows based on the filter (not automatic to allow for batching multiple filters)
	// 				api.onFilterChanged();
	// 			}''
	// 		}
	// 	},
	// }
};
</script>

<style lang="scss" scoped>
.table-data {
	overflow-x: auto;
	overflow-y: auto;
}
</style>